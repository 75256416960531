import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import config from "../../gatsby-config"
import SiteHeader from '../components/Layout/Header'
import TableOfContents from "../components/Layout/TableOfContents";

export default class DocTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.postBySlug;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    const apiLink = `/api${  slug}`;
    let openApiLink
    if(post.openApiPath) {
      const schemaPath = `/schemas/${  post.openApiPath}`;
      openApiLink = (
        <div>
          <a href={apiLink} className='btn btn-success btn-lg' role='button'> Swagger UI </a>
          &nbsp;&nbsp;&nbsp;
          <a href={schemaPath} className='btn btn-info btn-lg' role='button'> Open API Schema </a>
          &nbsp;&nbsp;&nbsp;
          <a href='mailto:dev@scalepoint.com' className='btn btn-dark btn-lg' role='button'> Feedback </a>
        </div>
      );
    }

    // remove first header of markdown html
    const postNodeHtml = postNode.html.replace(/<h([1]).*>.*<\/h\1>/,"");

    return (
      <Layout>
        <React.Fragment>
          <Helmet>
            <title>{`${post.title} | ${config.siteMetadata.siteTitle}`}</title>
          </Helmet>
          <BodyGrid>
            <HeaderContainer>
              <SiteHeader location={this.props.location} />
            </HeaderContainer>
            <ToCContainer>
              <TableOfContents
                posts={this.props.data.allPostTitles.edges}
                contentsType="doc"
                chapterTitles={config.siteMetadata.toCChapters}
              />
            </ToCContainer>
            <BodyContainer>
              <div className="contentPart">
                <h1>
                  {post.title}
                </h1>
                {openApiLink}
                <div dangerouslySetInnerHTML={{ __html: postNodeHtml }} />
              </div>
            </BodyContainer>
          </BodyGrid>
        </React.Fragment>
      </Layout>
    );
  }
}

const BodyGrid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: 300px 1fr;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
`
const BodyContainer = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  overflow: scroll;
  justify-self: center;
  width: 100%;
  padding: ${props => props.theme.sitePadding};
  @media screen and (max-width: 600px) {
    order: 2;
  }

  & > div {
    max-width: ${props => props.theme.contentWidthLaptop};
    margin: auto;
  }

  & > h1 {
    color: ${props => props.theme.accentDark};
  }
`
const HeaderContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  z-index: 2;
   @media screen and (max-width: 600px) {
    order: 1;
  }
`
const ToCContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background: ${props => props.theme.lightGrey};
  overflow: scroll;
   @media screen and (max-width: 600px) {
    order: 3;
    overflow: inherit;
  }
`
/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query LessonBySlug($slug: String!) {
    allPostTitles: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            section
            order
            type
            hidden
          }
          fields {
            slug
          }
        }
      }
    }
    postBySlug: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        openApiPath
      }
      fields {
        slug
      }
    }
  }
`;
